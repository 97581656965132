<template>
    <b-row>
      <div class="row-item">
        <span>{{ $t('device_view.device_count') }}：</span>
        <span :class="stat.total === 0 ? 'text-secondary' : 'text-primary'" class="font-weight-bold">
          {{ stat.total }}
        </span>
      </div>
      <div class="row-item">
        <span>{{ $t('device_view.offline_count') }}：</span>
        <span :class="stat.offline === 0 ? 'text-secondary' : 'text-danger'" class="font-weight-bold">
          {{ stat.offline }}
        </span>
        <b-img class="icon filter-invert" src="@/assets/images/bed_offline.png"/>
      </div>
      <div class="row-item">
        <span>{{ $t('device_view.exit_member') }}：</span>
        <span :class="stat.offBed === 0 ? 'text-secondary' : 'text-danger'" class="font-weight-bold">
          {{ stat.offBed }}
        </span>
        <b-img class="icon filter-invert" src="@/assets/images/bed_exit.png"/>
      </div>
      <div class="row-item">
        <b-button variant="primary" @click="showMoreDetail">{{ $t('device_view.more_detail') }}</b-button>
      </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BButton, BImg } from 'bootstrap-vue'

export default {
  name: 'TableStat',
  components: {
    BRow,
    BCol,
    BButton,
    BImg
  },
  props: {
    stat: { type: Object }
  },
  methods: {
    showMoreDetail: function () {
      this.$emit('showMoreDetail')
    }
  }
}
</script>

<style lang="scss" scoped>
.row-item {
  vertical-align: center;
  line-height: 40px;

  .icon {
    width: 26px;
    margin-left: 5px;
  }
}

</style>
