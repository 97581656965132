<template>
  <div>
    <b-row class="p-1">
      <b-col>
        <span>{{$t('device_view.device_count')}}：</span>
        <span :class="stat.total === 0 ? 'text-secondary' : 'text-primary'" class="font-weight-bold">{{ stat.total }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="ml-1">
        <b-row>
          <b-col class="pt-2 pb-2 pl-1 info-section">
            <span>{{$t('device_view.offline_device')}}：</span>
            <span :class="stat.offline === 0 ? 'text-secondary' : 'text-danger'" class="font-weight-bold">{{ stat.offline }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-2 pb-2 pl-1 mt-1 info-section">
            <span>{{$t('device_view.free_device')}}：</span>
            <span :class="stat.idle === 0 ? 'text-secondary' : 'text-primary'" class="font-weight-bold">{{ stat.idle }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="info-section ml-1 mr-1">
        <b-row>
          <b-col class="text-center pt-1 pb-1">
            <span>{{$t('device_view.online_device')}}：</span>
            <span :class="stat.online === 0 ? 'text-secondary' : 'text-success'" class="font-weight-bold">{{ stat.online }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <span>{{$t('device_view.member_status_onBed')}}：</span>
            <span :class="stat.onBed === 0 ? 'text-secondary' : 'text-success'" class="font-weight-bold">{{ stat.onBed }}</span>
            <div>
              <b-img class="w-60px filter-invert" src="@/assets/images/bed_on.png" />
            </div>
          </b-col>
          <b-col class="text-center">
            <span>{{$t('device_view.member_status_offBed')}}：</span>
            <span :class="stat.offBed === 0 ? 'text-secondary' : 'text-danger'" class="font-weight-bold">{{ stat.offBed }}</span>
            <div>
              <b-img class="w-60px filter-invert" src="@/assets/images/bed_exit.png" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol, BImg} from 'bootstrap-vue'

export default {
  name: "ModalMoreDetail",
  components: {
    BRow,
    BCol,
    BImg
  },
  props: {
    stat: {type: Object}
  }
}
</script>

<style lang="scss" scoped>
.info-section {
  border-radius: 5px;
  background-color: rgba(0,0,0,0.08);
  border: 1px solid #ccc;
  box-sizing: border-box;
  .dark-layout & {
    background-color: rgba(255,255,255,0.08);
    border-color: #555;

  }
}
</style>
