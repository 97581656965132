<template>
  <ul class="rooms-container">
    <li v-for="room in overview">
      <div class="room-header">
        <template v-if="$store.state.app.locale === 'en'">
          <span class="pr-1">{{ room.roomName }}</span><span>{{ room.floorName }}</span>
        </template>
        <template v-else>
          <span class="pr-1">{{ room.floorName }}</span><span>{{ room.roomName }}</span>
        </template>
      </div>
      <ul class="beds-container d-flex flex-wrap">
        <li
          v-for="bed in room.beds"
          class="bed cursor-pointer position-relative flex-shrink-0 shadow-sm"
          :class="getStatusColor(bed)"
        >
          <b-link
            router-tag="a"
            :to="{
              name: 'bedexitview_person',
              params: { id: common.encrypt(bed.memberID) }
            }"
          >
            <b-img class="photo photo-circle" :src="getPhoto(bed.memberPhoto)"/>
            <div class="bed-content">
              <div>
                <span>{{ bed.bedNumber }}</span>
              </div>
              <div class="time-height">
                <span>
                  {{ bed.latestUpdateTime !== null ? $moment(new Date(bed.latestUpdateTime)).format('HH:mm') : '--:--' }}
                </span>
              </div>
              <div>
                <b-img v-if="bed.isOnline !== true" class="icon" src="@/assets/images/bed_offline.png"/>
                <b-img v-else-if="bed.isOnBed === true" class="icon" src="@/assets/images/bed_on.png"/>
                <b-img v-else class="icon" src="@/assets/images/bed_exit.png"/>
              </div>
              <div class="position-absolute" style="bottom: 0.5rem; left: 0.5rem;">
                <b-img v-if="bed.isSilent" class="slash-bell" src="@/assets/images/slash_bell.png"/>
              </div>
            </div>
          </b-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import common from '@/common'

export default {
  name: 'TableView',
  components: {
    BImg
  },
  props: {
    overview: { type: Array }
  },
  data() {
    return {
      common
    }
  },
  methods: {
    getStatusColor: function (bed) {
      let cls = ''

      if (bed.isSilent) {
        cls += 'bed-silent '
      }

      if (bed.isOnline !== true) {
        cls += 'bed-offline'
      } else if (bed.isOnBed === true) {
        cls += 'bed-on'
      } else if (bed.isOnBed === false || bed.isOnBed == null) {
        cls += 'bed-exit'
      }

      return cls
    },
    showPersonRecord: function (bed) {
      this.$router.push({
        name: 'bedexitview_person',
        params: { id: common.encrypt(bed.memberID) }
      })
    },
    getPhoto: function (path) {
      if (!path) {
        return require('@/assets/images/user.png')
      }

      return common.getServerUrl() + path
    }
  }
}
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/variables/_variables';

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.rooms-container > li {
  border-radius: 5px;
  margin: 0 0 1rem 0;
  overflow: hidden;


  .room-header {
    padding: 0.6rem 1.2rem;
    color: #444;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);

    .dark-layout & {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ddd;
      border-color: #283046;
    }
  }

  .beds-container {
    padding: 1rem 0 0 1rem;
    background-color: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 2px 2px rgb(0,0,0,0.1);

    .dark-layout & {
      background-color: rgba(255, 255, 255, 0.04);
    }

    > .bed {
      margin: 0 1rem 1rem 0;
      padding: 0;
      list-style-type: none;
      width: 112px;
      text-align: center;
      color: #444;

      > a, > a:hover > a:visited {
        color: inherit;
        text-decoration: none;
      }

      > a {
        height: 100%;
        border-radius: 5px;
        border-width: 40px 0 0 0;
        border-style: solid;
        border-color: #fff;
        display: block;
        position: relative;
        background-color: #fff;
        .dark-layout & {
          background-color: #f8f8f8;
        }
      }

      .photo {
        border-radius: 50px;
        background: #fff;
        border: 2px solid #aaa;
        box-sizing: border-box;
        display: block;
        height: 52px;
        width: 52px;
        position: absolute;
        top: -26px;
        left: 50%;
        transform: translateX(-50%);

        .dark-layout & {
          background-color: #f8f8f8;
        }
      }

      .bed-content {
        padding: 2.5rem 0 1rem;
        width: 100%;
      }

    }

    > .bed-on > a {
      border-color: #9be59e;

      .photo {
        border-color: #9be59e;
      }
    }

    > .bed-exit > a {
      border-color: #f89e97;

      .photo {
        border-color: #f89e97;
      }
    }

    > .bed-offline > a {
      border-color: #999;

      .photo {
        border-color: #999;
      }
    }

    > .bed-silent > a {
      opacity: 0.6;
    }
  }

}

.icon {
  height: 26px;
}

.slash-bell {
  width: 18px;
}

.time-height {
  height: 24px;
}
</style>
